<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import ApiService from "@/common/service.api";
import {
  IonAlert,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonCard,
  IonCardContent,
  IonCheckbox,
  modalController,
  IonDatetime,
} from "@ionic/vue";
import { closeOutline } from "ionicons/icons";

const store = useStore();
const loading = ref(false);

const currentUser = store.state.user.userData;
const currentCie = ref({});
const idCie = currentUser.id;

const dates = ref([]);
const datesListVisible = ref(false);

function dateChanged(event) {
  console.log("date changed", event);
  dates.value = event.detail.value;
}

function closeModal() {
  return modalController.dismiss();
}

function getCurrentCieData() {
  loading.value = true;

  ApiService.get(
    "DossierManager/GetIndisponibilitaCie/" + idCie ||
      store.state.user.userData.id
  )
    .then((res) => {
      if (res) {
        dates.value = res.data;
      }
    })
    .finally(() => {
      loading.value = false;
    });
}

function setDisponibilita() {
  // loading.value = true;

  ApiService.put("DossierManager/SetIndisponibilitaCie/" + idCie, dates.value)
    .then((res) => {
      if (res) {
        console.log(res);
      }
    })
    .finally(() => {
      loading.value = false;
      closeModal();
    });
}

onMounted(() => {
  getCurrentCieData();
});
</script>

<template>
  <master-layout :showContentHeader="false" :showHeader="false">
    <ion-header>
      <ion-row>
        <ion-col size="9" class="ion-padding ion-margin-start">
          <h4>Indica le giornate di <br /><span>NON DISPONIBILITÀ</span></h4>
        </ion-col>
        <ion-col size="2" class="ion-align-items-center ion-margin-top">
          <ion-icon
            style="margin: 8px"
            size="large"
            @click="closeModal"
            :icon="closeOutline"
          ></ion-icon>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <p class="ion-text-center" v-if="loading">
            <ion-spinner name="crescent"></ion-spinner>
          </p>
          <ion-datetime
            v-else
            presentation="date"
            @ionChange="dateChanged"
            v-model="dates"
            :preferWheel="false"
            :multiple="true"
          ></ion-datetime>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="6">
          <button
            class="p-sm button-secondary mt-4 w-full"
            @click="datesListVisible = !datesListVisible"
          >
            {{ datesListVisible ? "Nascondi lista date" : "Mostra lista date" }}
          </button>
        </ion-col>
        <ion-col size="6" class="ion-text-end mt-4">
          <button class="p-sm button-primary w-full" @click="setDisponibilita">
            {{ loading ? "Caricamento..." : "Salva" }}
          </button>
        </ion-col>
      </ion-row>

      <ion-row v-if="datesListVisible">
        <ion-col size="12">
          <ion-list>
            <ion-item v-for="(date, index) in dates.sort()" :key="index">
              <ion-label class="px-2 py-1">{{ date }}</ion-label>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>

      <!-- <ion-row>
        <ion-col size="12">
          <ion-card class="ion-no-margin ion-no-padding no-background">
            <ion-card-content class="ion-no-padding">
              <ion-row>
                <ion-col size="12">
                  <ion-row
                    v-for="(day, index) in currentCie.weekDays"
                    :key="index"
                  >
                    <ion-col
                      size="12"
                      style="display: flex; align-items: center"
                    >
                      <ion-checkbox
                        v-model="day.enabled"
                        class="w-100"
                      ></ion-checkbox>
                      <ion-label class="ml-2">{{ day.text }}</ion-label>
                    </ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12">
                  <ion-row class="ion-justify-content-center">
                    <ion-col size="auto">
                      <button
                        @click="setDisponibilita"
                        :disabled="loading"
                        class="p-d button-primary"
                      >
                        {{ loading ? "Caricamento in corso..." : "Salva" }}
                      </button>
                    </ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row> -->
    </ion-header>
  </master-layout>
</template>
