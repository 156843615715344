<script setup>
import { ref, computed, onMounted, nextTick, defineProps } from "vue";
import { useStore } from "vuex";
import ApiService from "../common/service.api";
import {
  IonRow,
  IonCol,
  IonCard,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import CardCollapsable from "../components/Nuovi/CardCollapsable.vue";
import { useRouter } from "vue-router";
import CommonProgressBar from "../components/CommonProgressBar.vue";
import { checkmarkCircle, refresh, filter } from "ionicons/icons";
import { useDateFormat } from "@vueuse/core";

const props = defineProps({
  inProfile: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();

const store = useStore();
const loggedUser = computed(() => store.getters["user/getUserData"]);
const competenzeList = ref([]);
const loadingCompetenzeList = ref(false);
const showList1 = ref(false);
const showList2 = ref(false);

const filtro = ref({
  acquisite: null,
  badge: [],
  scadute: null,
});

function resetFiltro() {
  filtro.value = {
    acquisite: null,
    badge: [],
    scadute: null,
  };
}

const competenzeListFiltrate = computed(() => {
  let competenzeFiltrate = [...competenzeList.value];

  // Filtra per stato di acquisizione
  if (filtro.value.acquisite !== null) {
    competenzeFiltrate = competenzeFiltrate.filter((item) => {
      if (filtro.value.acquisite === "acquisite") {
        return item.acquisita;
      } else if (filtro.value.acquisite === "nonacquisite") {
        return !item.acquisita;
      }
      return true;
    });
  }

  // Filtra per tipo di badge
  if (filtro.value.badge.length > 0) {
    competenzeFiltrate = competenzeFiltrate.filter((item) => {
      const badgeTypes = filtro.value.badge;
      return badgeTypes.some((type) => {
        switch (type) {
          case "kpi":
            return (
              item.numBadgeKPIAcquisiti > 0 || item.numBadgeKPINecessari > 0
            );
          case "learning":
            return (
              item.numBadgeLearningAcquisiti > 0 ||
              item.numBadgeLearningNecessari > 0
            );
          case "landing":
            return (
              item.numBadgeLandingAcquisiti > 0 ||
              item.numBadgeLandingNecessari > 0
            );
          case "social":
            return (
              item.numBadgeSocialAcquisiti > 0 ||
              item.numBadgeSocialNecessari > 0
            );
          case "task":
            return (
              item.numBadgeTaskAcquisiti > 0 || item.numBadgeTaskNecessari > 0
            );
          default:
            return false;
        }
      });
    });
  }

  // Filtra per scadenza
  if (filtro.value.scadute !== null) {
    competenzeFiltrate = competenzeFiltrate.filter((item) => {
      const scaduta = daysUntil(item.dataScadenza) <= 0;
      if (filtro.value.scadute === "scadute") {
        return scaduta;
      } else if (filtro.value.scadute === "nonscadute") {
        return !scaduta;
      }
      return true;
    });
  }

  return competenzeFiltrate;
});

function daysUntil(date) {
  //how many days from today to date
  const today = new Date();
  const dateToCheck = new Date(date);
  const diffTime = Math.abs(dateToCheck - today);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

function goToCompetenze() {
  router.push("/competenze");
}

async function getCompetenzeStato() {
  competenzeList.value.splice(0);
  try {
    loadingCompetenzeList.value = true;
    let res = await ApiService.get(
      "Competenza/GetCompetenzeStato/" + loggedUser.value.id
    );

    competenzeList.value = res.data;

    setTimeout(async () => {
      await nextTick();
      showList1.value = true;
      loadingCompetenzeList.value = false;
    }, 1000);
  } catch (error) {
    console.log(error);
  }
}

onMounted(async () => {
  await getCompetenzeStato();
  setTimeout(async () => {
    await nextTick();
    showList2.value = true;
  }, 1);
});
</script>

<template>
  <master-layout
    v-if="!inProfile"
    :showContentHeader="false"
    :showHeader="true"
    smallTitle="Le tue"
    bigTitle="Competenze"
  >
    <ion-card>
      <ion-row>
        <ion-col size="4">
          <ion-select placeholder="Acquisizione" v-model="filtro.acquisite">
            <ion-select-option value="acquisite"> Acquisite</ion-select-option>
            <ion-select-option value="nonacquisite"
              >Non acquisite</ion-select-option
            >
          </ion-select>
        </ion-col>
        <ion-col size="4">
          <ion-select
            placeholder="Tipo Badge"
            :multiple="true"
            v-model="filtro.badge"
          >
            <ion-select-option value="kpi">Kpi</ion-select-option>
            <ion-select-option value="learning">Learning</ion-select-option>
            <ion-select-option value="landing">Landing</ion-select-option>
            <ion-select-option value="social">Social</ion-select-option>
            <ion-select-option value="task">Task</ion-select-option>
          </ion-select>
        </ion-col>
        <ion-col size="4">
          <ion-select placeholder="Scadenza" v-model="filtro.scadute">
            <ion-select-option value="scadute">Scadute</ion-select-option>
            <ion-select-option value="nonscadute"
              >Non scadute</ion-select-option
            >
          </ion-select>
        </ion-col>
      </ion-row>

      <ion-row class="w-full flex justify-content-end align-items-center">
        <ion-col size="12">
          <div class="w-full flex justify-content-end">
            <button @click="resetFiltro" class="button-primary p-sm mr-2">
              <ion-icon :icon="refresh"></ion-icon>
              <span class="ml-2">Resetta filtro</span>
            </button>
          </div>
        </ion-col>
      </ion-row>
    </ion-card>

    <template v-if="loadingCompetenzeList">
      <ion-row class="ion-justify-content-center ion-align-items-center">
        <ion-col size="auto" class="text-color-primary">
          Caricamento in corso...
        </ion-col>
      </ion-row>
    </template>

    <transition-group
      name="block-slide-in"
      :style="{ '--i': competenzeListFiltrate.length }"
    >
      <template v-for="(listItem, i) in competenzeListFiltrate" :key="i">
        <ion-card
          v-if="showList1 && showList2"
          :style="{ '--i': i }"
          class="my-4"
        >
          <card-collapsable :withPadding="false">
            <template #title>
              <div class="pb-2">
                <ion-row class="w-full text-color-light font-bold text-20">
                  <ion-col>
                    <div
                      class="w-full flex justify-content-start align-items-center"
                    >
                      <ion-icon
                        v-if="listItem.acquisita"
                        :icon="checkmarkCircle"
                        class="mr-2 font-bold text-26"
                      ></ion-icon>
                      <span class="font-bold">{{
                        listItem.nomeCompetenza
                      }}</span>
                    </div>
                  </ion-col>
                </ion-row>
                <ion-row class="text-12" style="color: var(--card1-text-color)">
                  <ion-col size="12" class="ion-margin-top">
                    <span class="text-16 font-bold">Badges</span>
                  </ion-col>

                  <ion-col v-if="listItem.numBadgeKPINecessari" size="6">
                    <div
                      class="w-full font-bold bg-secondary p-2 border-radius-client text-color-primary"
                    >
                      KPI: {{ listItem.numBadgeKPIAcquisiti || 0 }}/{{
                        listItem.numBadgeKPINecessari
                      }}
                    </div>
                  </ion-col>

                  <ion-col v-if="listItem.numBadgeLearningNecessari" size="6">
                    <div
                      class="w-full font-bold bg-secondary p-2 border-radius-client text-color-primary"
                    >
                      Learning: {{ listItem.numBadgeLearningAcquisiti || 0 }}/{{
                        listItem.numBadgeLearningNecessari
                      }}
                    </div>
                  </ion-col>

                  <ion-col v-if="listItem.numBadgeLandingNecessari" size="6">
                    <div
                      class="w-full font-bold bg-secondary p-2 border-radius-client text-color-primary"
                    >
                      Landing: {{ listItem.numBadgeLandingAcquisiti || 0 }}/{{
                        listItem.numBadgeLandingNecessari
                      }}
                    </div>
                  </ion-col>

                  <ion-col v-if="listItem.numBadgeSocialNecessari" size="6">
                    <div
                      class="w-full font-bold bg-secondary p-2 border-radius-client text-color-primary"
                    >
                      Social: {{ listItem.numBadgeSocialAcquisiti || 0 }}/{{
                        listItem.numBadgeSocialNecessari
                      }}
                    </div>
                  </ion-col>

                  <ion-col v-if="listItem.numBadgeTaskNecessari" size="6">
                    <div
                      class="w-full font-bold bg-secondary p-2 border-radius-client text-color-primary"
                    >
                      Task: {{ listItem.numBadgeTaskAcquisiti || 0 }}/{{
                        listItem.numBadgeTaskNecessari
                      }}
                    </div>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="12">
                    <common-progress-bar
                      v-if="listItem.completamento < 100"
                      style="height: 10px"
                      class="border-radius-client"
                      :value="listItem.completamento / 100"
                    ></common-progress-bar>
                  </ion-col>
                </ion-row>
              </div>
            </template>
            <template #body>
              <ion-row>
                <ion-col
                  size="4"
                  v-for="badge in listItem.userBadges"
                  :key="badge.id"
                >
                  <ion-row>
                    <ion-col size="12">
                      <img :src="badge.url_image" class="icon-rounded" />
                    </ion-col>
                  </ion-row>
                  <common-progress-bar
                    :value="badge.perc_completamento / 100"
                  ></common-progress-bar>
                  <ion-row>
                    <ion-col size="12" class="ion-text-center">
                      <span class="font-bold">{{ badge.title["it-IT"] }}</span>
                    </ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>

              <ion-row>
                <ion-col
                  size="4"
                  v-for="badge in listItem.userBadgesNotCompleted"
                  :key="badge.id"
                >
                  <ion-row>
                    <ion-col size="12">
                      <img
                        :src="badge.url_image"
                        class="icon-rounded"
                        style="filter: grayscale(100)"
                      />
                    </ion-col>
                  </ion-row>
                  <common-progress-bar
                    :value="badge.perc_completamento / 100"
                  ></common-progress-bar>
                  <ion-row>
                    <ion-col size="12" class="ion-text-center">
                      <span class="font-bold">{{ badge.title["it-IT"] }}</span>
                    </ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
            </template>
            <template #footer>
              <div class="w-full flex justify-content-end align-items-center">
                Scadenza:
                {{ useDateFormat(listItem.dataScadenza, "DD/MM/YYYY").value }}
                ({{ daysUntil(listItem.dataScadenza) }} giorni)
              </div>
            </template>
          </card-collapsable>
        </ion-card>
      </template>
    </transition-group>
  </master-layout>

  <div v-else>
    <template v-if="loadingCompetenzeList">
      <ion-row class="ion-justify-content-center ion-align-items-center">
        <ion-col size="auto" class="text-color-primary">
          Caricamento in corso...
        </ion-col>
      </ion-row>
    </template>

    <template
      v-else-if="competenzeList.filter((item) => item.acquisita).length == 0"
    >
      <ion-row class="ion-justify-content-center ion-align-items-center">
        <ion-col size="auto" class="text-color-primary">
          Sembra che non ci siano competenze acquisite
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="8" offset="2" class="ion-padding">
          <button class="p-sm button-primary" @click="goToCompetenze">
            Vai alla lista delle competenze disponibili
          </button>
        </ion-col>
      </ion-row>
    </template>

    <transition-group
      name="block-slide-in"
      :style="{ '--i': competenzeList.length }"
    >
      <template
        v-for="(listItem, i) in competenzeList.filter((item) => item.acquisita)"
        :key="i"
      >
        <ion-card
          v-if="showList1 && showList2"
          :style="{ '--i': i }"
          class="ml-4 my-2 p-2"
        >
          <div class="w-full flex justify-content-start align-items-center">
            <ion-icon :icon="checkmarkCircle" class="mr-2"></ion-icon>
            <span class="font-bold text-16">{{ listItem.nomeCompetenza }}</span>
          </div>
        </ion-card>
      </template>
    </transition-group>
  </div>
</template>
